import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DataTableService from 'services/DataTableService';

export const initialState = {
    objectListData: {
        objects: [],
        isLoading: true,
        isLoaded: false,
    },
    relationData: {

    },
    selectedObjectData: {
        object: null,
        isLoading: false,
        isLoaded: false,
    },
    filters: {},
    sorter: {},
    pagination: {}
}

export const getRelationObjects = createAsyncThunk('dataTable/getRelationObjects', async ({id, params}, { rejectWithValue }) => {
    try {
        const response = await DataTableService.getObjects(id, { ...params , pageSize: 100});
        return { objects: response?.result?.objects, id,totalRecords:response?.result?.totalRecords }
    } catch (err) {
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})

export const getObjects = createAsyncThunk('dataTable/getObjects', async ({id, params}, { rejectWithValue }) => {
    try {
        const response = await DataTableService.getObjects(id, params);
        return response
    } catch (err) {
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})

export const createObjects = createAsyncThunk('dataTable/createObjects', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await DataTableService.createObjects(id, data);
        if (response.result) {
            return response;
        } else {
            return rejectWithValue(response.message);
        }
    } catch (err) {
        console.log(err)
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})
export const deleteObjects = createAsyncThunk('dataTable/deleteObjects', async ({ id, ids }, { rejectWithValue }) => {
    try {
        const response = await DataTableService.deleteObjects(id, ids);
        if (response.result) {
            return response;
        } else {
            return rejectWithValue(response.message);
        }
    } catch (err) {
        console.log(err)
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})
export const updateObject = createAsyncThunk('dataTable/updateObject', async ({ tableSchemaId, id, data }, { rejectWithValue }) => {
    try {
        const response = await DataTableService.updateObject(tableSchemaId, id, data);
        if (response.object) {
            return response.object;
        } else {
            return rejectWithValue(response.message);
        }
    } catch (err) {
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})
export const createObject = createAsyncThunk('dataTable/createObject', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await DataTableService.createObject(id, data);
        if (response.object) {
            return response.object;
        } else {
            return rejectWithValue(response.message);
        }
    } catch (err) {
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})
export const assignUsers = createAsyncThunk('dataTable/assignUsers', async ({ tableSchemaId, id, data }, { rejectWithValue }) => {
	try {
		const response = await DataTableService.assignUsers(tableSchemaId, id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const deleteObjectsByFilter = createAsyncThunk('dataTable/deleteObjectsByFilter', async ({ id, params }, { rejectWithValue }) => {
    try {
        const response = await DataTableService.deleteObjectsByFilter(id, params);
        return response
    } catch (err) {
        console.log(err)
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})
export const bulkAssignUsers = createAsyncThunk('dataTable/bulkAssignUsers', async ({ tableSchemaId, data, params }, { rejectWithValue }) => {
	try {
		const response = await DataTableService.bulkAssignUsers(tableSchemaId, data, params);
		if (response) {
			return response;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const bulkUpdate = createAsyncThunk('dataTable/bulkUpdate', async ({ tableSchemaId, data, params }, { rejectWithValue }) => {
	try {
		const response = await DataTableService.bulkUpdate(tableSchemaId, data, params);
		if (response) {
			return response;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const bulkAction = createAsyncThunk('dataTable/bulkAction', async ({ tableSchemaId,params, data }, { rejectWithValue }) => {
	try {
		const response = await DataTableService.bulkAction(tableSchemaId, data, params);
		if (response) {
			return response;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const copyObject = createAsyncThunk('dataTable/copyObject', async ({ tableSchemaId, id, data }, { rejectWithValue }) => {
    try {
        const response = await DataTableService.copyRecord(tableSchemaId, id, data);
        if (response.object) {
            return response.object;
        } else {
            return rejectWithValue(response.message);
        }
    } catch (err) {
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})
export const objectSlice = createSlice({
    name: 'dataTable',
    initialState,
    reducers: {
        setSelectedObjectData: (state, action) => {
            const { object } = action?.payload;
            state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
        },
        setFilters: (state, action) => {
            state.filters = action?.payload;
        },
        setSorter: (state, action) => {
            state.sorter = action?.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action?.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getObjects.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })
            .addCase(getObjects.pending, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
            })
            .addCase(getObjects.rejected, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
            })
            .addCase(createObjects.pending, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
            })
            .addCase(createObjects.rejected, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
            })
            .addCase(createObjects.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })
            .addCase(deleteObjects.pending, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
            })
            .addCase(deleteObjects.rejected, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
            })
            .addCase(deleteObjects.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })
            .addCase(updateObject.pending, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
            })
            .addCase(updateObject.rejected, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
            })
            .addCase(updateObject.fulfilled, (state, action) => {
                const updatedObject = action.payload;
                const objects = state.objectListData.objects.map((item) =>
                    item._id === updatedObject._id ? updatedObject : item
                );
                state.objectListData = { ...state.objectListData, objects, isLoaded: true, isLoading: false }
                // state.objectListData.objects = objects;
            })
            .addCase(getRelationObjects.fulfilled, (state, action) => {
                const { objects, id, totalRecords } = action?.payload
                state.relationData = { ...state.relationData, [id]: { objects, isLoaded: true, isLoading: false,totalRecords } }
            })
            .addCase(getRelationObjects.pending, (state, action) => {
                const { id } = action?.meta?.arg
                state.relationData = { ...state.relationData, [id]: { ...state.relationData[id], isLoaded: false, isLoading: true } }
            })
            .addCase(getRelationObjects.rejected, (state) => {
                // state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
            })
            .addCase(assignUsers.fulfilled, (state, action) => {
                const updatedObject = action.payload;
                const objects = state.objectListData.objects.map((item) =>
                    item._id === updatedObject._id ? updatedObject : item
                );
                state.objectListData = { ...state.objectListData, objects, isLoaded: true, isLoading: false }
                // state.objectListData.objects = objects;
            })
            .addCase(bulkAssignUsers.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })
            .addCase(bulkUpdate.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })
            .addCase(deleteObjectsByFilter.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })
            .addCase(copyObject.pending, (state) => {
                // state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
            })
            .addCase(copyObject.rejected, (state) => {
                // state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
            })
            .addCase(copyObject.fulfilled, (state, action) => {
                const updatedObject = action.payload;
                const objects = state.objectListData.objects.map((item) =>
                    item._id === updatedObject._id ? updatedObject : item
                );
                state.objectListData = { ...state.objectListData, objects, isLoaded: true, isLoading: false }
                // state.objectListData.objects = objects;
            })
            .addCase(bulkAction.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })

    },
})

export const {
    setSelectedObjectData,
    setFilters,
    setSorter,
    setPagination
} = objectSlice.actions

export default objectSlice.reducer
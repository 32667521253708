import fetch from 'auth/FetchInterceptor'
import { stringify } from 'qs';

const urlKey = '/data-table-history';

export default class DataTableHistoryService {

  static async getObjects(id, params = {}, recordId) {
    return await fetch({
      url: `/${id}${urlKey}`,
      method: 'get',
      params: {...params, recordId},
      paramsSerializer: params => {
        return stringify(params)
      }
    })
  }
  static async restore(id, historyId) {
    return await fetch({
      url: `/${id}${urlKey}/restore`,
      method: 'post',
     data: {historyId}
    })
  }
  static async deleteObject(id, historyId) {
    return await fetch({
      url: `/${id}${urlKey}/${historyId}`,
      method: 'delete',
    })
  }
}